/* General Styles */
.App {
  display: flex;
  flex-direction: column; /* Change to a column layout */
  align-items: stretch; /* Aligns children vertically */
  font-family: Arial, sans-serif;
}

/* Header Styles */
.header {
  height: 60px; /* Set the desired height for your header */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
}

/* Main Content Styles (No change needed here) */
.main-content {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

thead {
  background-color: #f2f2f2;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

.header nav {
  display: flex;
  justify-content: center;
  background-color: #333; /* Background color for the header */
}

.sidebar-button {
  padding: 10px 20px; /* Adjust the padding as needed */
  background-color: #808080; /* Grey background color */
  color: #fff; /* Text color */
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease; /* Add a smooth hover effect */
  margin-right: 10px; /* Add some horizontal spacing between buttons */
  display: inline-block; /* Make the buttons display inline */
}

.sidebar-button:hover {
  background-color: #555; /* Darker grey on hover */
}

/* Responsive Design (No change needed here) */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
  }
  
  .main-content {
    padding: 10px;
  }

  table {
    font-size: 14px;
  }
}
